import { format } from 'date-fns';
import { de } from 'date-fns/locale';
import propTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { collections } from '../../../axios';
import useContext from '../../../hooks/useContext';
import TextBox from '../textBox/TextBox';
import * as S from './News.styles';

const News = ({ headline }) => {
  const newsRef = useRef(null);
  const { currentPage } = useContext();
  const currentPageNews = currentPage.news;
  const [news, setNews] = useState(null);
  const isPublishingDateInFuture = (publishingDate) => {
    if (!publishingDate) return false;
    const pubDate = new Date(publishingDate).getTime();
    const now = new Date().getTime();
    return now < pubDate;
  };
  const newsHash = window.location.hash.substr(1);

  useEffect(() => {
    if (newsHash && newsRef.current) {
      window.scrollTo(0, newsRef.current.offsetTop);
    }
  }, [news]);

  useEffect(() => {
    const fetchNews = async () => {
      const { data: fetchedNews } = await collections.get('/get/news');

      if (currentPageNews && currentPageNews.length > 0) {
        setNews(
          fetchedNews.filter((newsItem) => {
            return currentPageNews.find(
              (currentPageNewsItem) => currentPageNewsItem._id === newsItem._id
            );
          })
        );
      } else {
        setNews(fetchedNews);
      }
    };

    fetchNews();
  }, []);

  return (
    news && (
      <S.News ref={newsRef}>
        <TextBox headline={headline}>
          {news.map(
            ({ title, detailText, date }) =>
              detailText &&
              !isPublishingDateInFuture(date) && (
                <S.NewsContent key={title}>
                  {date && (
                    <S.NewsDate>
                      {format(new Date(date), 'dd. MMMM yyyy', { locale: de })}
                    </S.NewsDate>
                  )}
                  <S.NewsTitle>{title}</S.NewsTitle>
                  <S.NewsDetails dangerouslySetInnerHTML={{ __html: detailText }} />
                  <hr />
                </S.NewsContent>
              )
          )}
        </TextBox>
      </S.News>
    )
  );
};

News.propTypes = {
  headline: propTypes.string,
};

export default News;
