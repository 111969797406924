import { css } from '@emotion/core';
import styled from '@emotion/styled';

import mq from '../../styles/mq';

export const Navigation = styled.nav`
  background-color: ${({ theme }) => theme.colors.white};
  padding: ${({ theme }) => `${theme.globals.spacing}px 0 8px 0`};
  position: relative;

  ${mq.lg} {
    padding-bottom: 0;
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: ${({ theme }) => theme.colors.accent};
      height: 22px;
      z-index: 0;
    }
  }
`;

export const NavigationContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  z-index: 1;

  ${mq.lg} {
    flex-wrap: no-wrap;
  }
`;

export const NavigationList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  ${mq.lg} {
    width: auto;
  }
`;

export const NavigationItem = styled.li`
  display: flex;
  margin-bottom: 1.6rem;
  width: 50%;
  justify-content: center;

  ${mq.lg} {
    margin: 0;
    width: auto;
  }

  ${({ isActive, theme }) =>
    isActive &&
    css`
      img {
        border-color: transparent !important;
        background-color: ${theme.colors.accent};
      }
    `}
`;

export const NavigationLink = styled.a`
  align-items: center;
  color: ${({ theme }) => theme.colors.text};
  display: flex;
  flex-direction: column;
  font-size: 1.4rem;
  font-family: ${({ theme }) => theme.fonts.secondary};
  text-decoration: none;

  img {
    border: 2px solid ${({ theme }) => theme.colors.text};
    border-radius: 50%;
    transition: border 0.2s, background-color 0.2s;
    padding: 6px;
    width: 50px;
    height: 50px;
  }

  ${mq.lg} {
    margin-right: 3.2rem;

    img {
      margin-bottom: 3rem;
      width: 70px;
      height: 70px;
    }

    &:hover {
      img {
        border-color: transparent;
        background-color: ${({ theme }) => theme.colors.accent};
      }
    }
  }
`;

export const PageIcon = styled.div`
  margin-left: auto;
  width: 350px;

  ${mq.lg} {
    position: relative;
    top: -16px;
  }
`;
