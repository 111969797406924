import styled from '@emotion/styled';
import mq from '../../../styles/mq';

export const News = styled.div``;

export const NewsContent = styled.div`
  margin-bottom: 32px;

  &:last-of-type {
    margin-bottom: 0;

    hr {
      display: none;
    }
  }

  hr {
    height: 1px;
    background-color: ${({ theme }) => theme.colors.secondary};
    border: none;
  }
`;

export const NewsTitle = styled.h3``;

export const NewsDetails = styled.div`
  img {
    max-width: 100%;

    ${mq.md} {
      max-width: 50%;
    }
  }
`;

export const NewsDate = styled.p`
  margin-bottom: 8px !important;
  font-style: italic;
`;
