import styled from '@emotion/styled';

import mq from '../../styles/mq';

export const Breadcrumb = styled.ul`
  text-transform: uppercase;
  display: flex;
  font-size: 1.4rem;
  margin-bottom: ${({ theme }) => theme.globals.spacing}px;
  flex-wrap: wrap;

  ${mq.lg} {
    font-size: 1.8rem;
    margin-bottom: ${({ theme }) => theme.globals.spacing * 2}px;
  }
`;

export const BreadcrumbItem = styled.li`
  display: flex;
  margin-right: 6px;
`;

export const BreadcrumbLink = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.grey};
`;
