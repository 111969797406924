import propTypes from 'prop-types';
import React from 'react';

import * as S from './Separator.styles';

const Link = ({ margin }) => {
  return <S.Separator margin={margin} />;
};

Link.propTypes = {
  margin: propTypes.string,
};

export default Link;
