import styled from '@emotion/styled';

import mq from '../../../styles/mq';

export const Download = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1.6rem;

  ${mq.sm} {
    max-width: 50%;
  }
`;

export const DownloadImage = styled.div`
  img {
    margin-bottom: 0.8rem;
  }
`;

export const DownloadText = styled.div`
  margin-bottom: 0.8rem;
`;

export const DownloadTitle = styled.div`
  font-weight: bold;
  margin-bottom: 0.8rem;
`;

export const DownloadLink = styled.a`
  display: flex;
  align-items: center;

  svg {
    margin-right: 0.8rem;
  }
`;
