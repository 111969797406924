import { css } from '@emotion/core';
import styled from '@emotion/styled';

export const Title = styled.h2`
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme, backgroundColor }) => backgroundColor || theme.colors.primary};
  padding: ${({ theme }) => `16px ${theme.globals.spacing}px 10px ${theme.globals.spacing}px`};

  ${({ secondBackgroundColor, backgroundColor }) =>
    secondBackgroundColor &&
    css`
      background: linear-gradient(to right, ${backgroundColor} 0%, ${secondBackgroundColor} 100%);
    `}
`;
