import propTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { collections } from '../../../axios';
import CockpitComponents from '../../cockpitComponents/CockpitComponents';
import Image from '../image/Image';
import TextBox from '../textBox/TextBox';
import * as S from './Findings.styles';

const Findings = () => {
  const [findings, setFindings] = useState(null);

  useEffect(() => {
    const fetchFindings = async () => {
      const { data } = await collections.get('/get/findings');

      setFindings(data);
    };

    fetchFindings();
  }, []);

  return (
    findings &&
    findings.map((finding) => (
      <TextBox key={finding.headline} headline={finding.headline}>
        <S.FindingObjective>Ziel: {finding.objective}</S.FindingObjective>
        <S.FindingPeriod>
          <strong>Zeitraum:&nbsp;</strong>
          {finding.period}
        </S.FindingPeriod>
        <S.FindingParticipants>
          <strong>Beteiligte Partner:&nbsp;</strong>
          {finding.participants}
          <S.FindingParticipantsLogos>
            {finding.participantsIcon &&
              finding.participantsIcon.map(({ value }) => <Image key={value.path} image={value} />)}
          </S.FindingParticipantsLogos>
        </S.FindingParticipants>
        {finding.content && <CockpitComponents components={finding.content} />}
      </TextBox>
    ))
  );
};

Findings.propTypes = {
  headline: propTypes.string,
};

export default Findings;
