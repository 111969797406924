import propTypes from 'prop-types';
import React from 'react';

import useContext from '../../hooks/useContext';
import * as S from './Title.styles';

const Title = ({ text }) => {
  const { currentPage } = useContext();
  const backgroundColor = currentPage.color && currentPage.color.firstColor;
  const secondBackgroundColor = currentPage.color && currentPage.color.secondColor;

  return (
    <S.Title backgroundColor={backgroundColor} secondBackgroundColor={secondBackgroundColor}>
      {text}
    </S.Title>
  );
};

Title.propTypes = {
  text: propTypes.string.isRequired,
};

export default Title;
