const theme = (settings) => ({
  colors: {
    white: '#ffffff',
    grey: '#7A7C7D',
    error: '#e85555',
    link: '#5d93c9',
    text: settings.textColor,
    primary: settings.primaryColor,
    secondary: settings.secondaryColor,
    accent: settings.accentColor,
  },
  fonts: {
    primary: 'Open Sans',
    secondary: 'Syne',
  },
  globals: {
    spacing: 24,
  },
});

export default theme;
