import propTypes from 'prop-types';
import React from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';

import useContext from '../../../hooks/useContext';
import Title from '../../title/Title';
import Image from '../image/Image';
import * as S from './Partner.styles';

const Partner = ({ headline }) => {
  const { partner, pages } = useContext();
  const router = useLocation();
  const partnerParentPageData = pages.find((page) => page._id === '5fbbc573a3719d16e95e3091');
  const slug = (partnerParentPageData && partnerParentPageData.title_slug) || router.query.page;

  return (
    <S.Partner>
      <Title text={headline} />
      <S.PartnerList>
        {partner.map((partnerItem) => {
          if (!partnerItem.published) return null;
          return (
            <S.PartnerItem key={partnerItem.title}>
              <Link to={`/${slug}/${partnerItem.title_slug}`} component={S.PartnerLink}>
                <>
                  <h3>{partnerItem.title}</h3>
                  <Image image={partnerItem.logo} />
                </>
              </Link>
            </S.PartnerItem>
          );
        })}
      </S.PartnerList>
    </S.Partner>
  );
};

Partner.propTypes = {
  headline: propTypes.string.isRequired,
};

export default Partner;
