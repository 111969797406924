import propTypes from 'prop-types';
import { TextBox } from '../cockpit';

const getYouTubeEmbedUrl = (url) => {
  const videoId = url.split('/').pop(); // Extract the video ID from youtu.be link
  return `https://www.youtube.com/embed/${videoId}`;
};

const Video = ({ src, poster, headline, isYouTube }) => {
  return (
    <TextBox headline={headline} resetPadding>
      {isYouTube ? (
        <div
          style={{ position: 'relative', paddingBottom: '56.25%', height: 0, overflow: 'hidden' }}
        >
          <iframe
            style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
            src={getYouTubeEmbedUrl(src)}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      ) : (
        <video height="100%" width="100%" controls preload="none" poster={poster}>
          <source src={src} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )}
    </TextBox>
  );
};

Video.propTypes = {
  src: propTypes.string.isRequired,
  poster: propTypes.string,
  headline: propTypes.string,
  isYouTube: propTypes.bool.isRequired,
};

export default Video;
