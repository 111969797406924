import React, { useEffect, useState } from 'react';

import { collections } from '../../axios';
import useContext from '../../hooks/useContext';
import TextBox from '../cockpit/textBox/TextBox';
import Slider from '../slider/Slider';
import * as S from './News.styles';

const News = () => {
  const { currentPage } = useContext();
  const currentPageNews = currentPage.news;
  const [news, setNews] = useState(null);

  useEffect(() => {
    const fetchNews = async () => {
      const { data: fetchedNews } = await collections.get('/get/news');

      if (currentPageNews && currentPageNews.length > 0) {
        setNews(
          fetchedNews.filter((newsItem) => {
            return currentPageNews.find(
              (currentPageNewsItem) => currentPageNewsItem._id === newsItem._id
            );
          })
        );
      } else {
        setNews(fetchedNews);
      }
    };

    fetchNews();
  }, []);

  return (
    news && (
      <S.News>
        <TextBox headline="News">
          <Slider items={news} type="news" />
        </TextBox>
      </S.News>
    )
  );
};

export default News;
