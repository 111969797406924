const getCurrentPageData = (pages, router, siteSettings, partner) => {
  const pathname = router.pathname;
  const page = pathname.split('/').filter((item) => item)[0];
  const subPage = pathname.split('/').filter((item) => item)[1];

  const homepageData = pages.find((page) => page._id === siteSettings.homepage._id);
  const pageData = pages.find((item) => item.title_slug === page);
  const subPageData = partner.find((page) => page.title_slug === subPage);

  if (!pageData && !subPageData) return homepageData;

  if (pageData && !subPageData) {
    return pageData;
  }

  if (subPageData && pageData) {
    return subPageData;
  }

  return null;
};

export default getCurrentPageData;
