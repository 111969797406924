import { ChevronRight } from '@material-ui/icons';
import propTypes from 'prop-types';
import React from 'react';

import * as S from './Link.styles';

const Link = ({ text, url, rotatedIcon }) => {
  return (
    <S.Link href={url} rotatedIcon={rotatedIcon}>
      <ChevronRight style={{ fontSize: 24 }} />
      {text}
    </S.Link>
  );
};

Link.propTypes = {
  text: propTypes.string,
  url: propTypes.string,
  rotatedIcon: propTypes.bool,
};

export default Link;
