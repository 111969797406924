import { css } from '@emotion/core';
import styled from '@emotion/styled';

export const Link = styled.a`
  display: flex;
  align-items: center;
  margin-bottom: 1.2rem;

  ${({ rotatedIcon }) =>
    rotatedIcon &&
    css`
      svg {
        transform: rotate(180deg);
      }
    `}
`;
