import styled from '@emotion/styled';

export const News = styled.div`
  img {
    pointer-events: none;
  }

  div {
    margin-bottom: 0.8rem;
  }

  span {
    color: ${({ theme }) => theme.colors.text};
  }
`;
