import styled from '@emotion/styled';

export const FindingObjective = styled.h3``;

export const FindingPeriod = styled.div`
  margin-bottom: 0.8rem;
`;

export const FindingParticipants = styled.div`
  align-items: flex-end;
  margin-bottom: 1.6rem;
  display: flex;
  flex-wrap: wrap;
`;

export const FindingParticipantsLogos = styled.div`
  align-items: flex-end;
  display: flex;

  img {
    max-width: 120px;
    margin: 0 0.4rem;
  }
`;
