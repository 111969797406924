import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';

import useContext from '../../hooks/useContext';
import * as S from './Breadcrumb.styles';

const Breadcrumb = () => {
  const { siteSettings, currentPage } = useContext();
  const router = useLocation();
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const isMainPage = currentPage._id === siteSettings.homepage._id;
  const pathname = router.pathname;

  useEffect(() => {
    let newBreadcrumbs = [];

    if (router.pathname === '/preview') return;

    if (isMainPage) {
      newBreadcrumbs.push({
        title: `${siteSettings.homepage.display} /`,
        href: '/',
      });
    } else {
      const page = pathname.split('/').filter((item) => item)[0];
      const subPage = pathname.split('/').filter((item) => item)[1];

      newBreadcrumbs.push(
        {
          title: `${page} /`,
          href: `/${page}`,
        },
        {
          title: subPage,
          href: `/${page}/${subPage}`,
        }
      );
    }

    setBreadcrumbs(newBreadcrumbs.filter((item) => item.title));
  }, [currentPage]);

  return (
    breadcrumbs.length >= 1 && (
      <S.Breadcrumb>
        {breadcrumbs.map((item) => (
          <S.BreadcrumbItem key={item.title}>
            <Link to={item.href} component={S.BreadcrumbLink}>
              {item.title}
            </Link>
          </S.BreadcrumbItem>
        ))}
      </S.Breadcrumb>
    )
  );
};

export default Breadcrumb;
