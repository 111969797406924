import propTypes from 'prop-types';
import React from 'react';
import useContext from '../../../hooks/useContext';
import CockpitComponents from '../../cockpitComponents/CockpitComponents';
import Title from '../../title/Title';
import * as S from './TextBox.styles';

const TextBox = ({ headline, children, componentChildren, resetPadding }) => {
  const { currentPage } = useContext();
  const color = currentPage.color && currentPage.color.firstColor;

  return (
    <S.TextBox>
      <Title text={headline} />
      <S.TextBoxContent color={color} resetPadding={resetPadding}>
        {componentChildren && <CockpitComponents components={componentChildren} />}
        {children}
      </S.TextBoxContent>
    </S.TextBox>
  );
};

TextBox.propTypes = {
  headline: propTypes.string,
  children: propTypes.any,
  componentChildren: propTypes.array,
  resetPadding: propTypes.bool,
};

export default TextBox;
