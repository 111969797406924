import { Add, Remove } from '@material-ui/icons';
import propTypes from 'prop-types';
import React, { useState } from 'react';

import CockpitComponents from '../../cockpitComponents/CockpitComponents';
import * as S from './ShowMore.styles';

const TextBox = ({ title, componentChildren }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleShowMoreLinkClick = (event) => {
    event.preventDefault();

    setIsOpen((prevState) => !prevState);
  };

  return (
    <S.ShowMore>
      <S.ShowMoreLink isOpen={isOpen} href="#" onClick={handleShowMoreLinkClick}>
        {isOpen ? <Remove style={{ fontSize: 24 }} /> : <Add style={{ fontSize: 24 }} />}
        {title}
      </S.ShowMoreLink>
      <S.ShowMoreContent isOpen={isOpen}>
        {componentChildren && <CockpitComponents components={componentChildren} />}
      </S.ShowMoreContent>
    </S.ShowMore>
  );
};

TextBox.propTypes = {
  title: propTypes.string,
  componentChildren: propTypes.array,
};

export default TextBox;
