import { GetApp } from '@material-ui/icons';
import propTypes from 'prop-types';
import React from 'react';

import Image from '../image/Image';
import * as S from './Download.styles';

const Download = ({ title, text, image, file }) => {
  const fileTypeArr = file.title.split('.');
  const fileType = fileTypeArr[fileTypeArr.length - 1];
  const fileSize = file.size / 1024 / 1024;

  return (
    <S.Download>
      <S.DownloadImage>
        <Image image={image} />
      </S.DownloadImage>
      <S.DownloadTitle>{title}</S.DownloadTitle>
      <S.DownloadText>{text}</S.DownloadText>
      <S.DownloadLink
        href={process.env.REACT_APP_COCKPIT_BASE_URL + '/storage/uploads' + file.path}
      >
        <GetApp style={{ fontSize: 24 }} /> {`${fileType} (${fileSize.toFixed(2)} mb)`}
      </S.DownloadLink>
    </S.Download>
  );
};

Download.propTypes = {
  title: propTypes.string,
  text: propTypes.string,
  image: propTypes.object,
  file: propTypes.object,
};

export default Download;
