import { css } from '@emotion/core';
import styled from '@emotion/styled';

export const TextBox = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  margin-bottom: ${({ theme }) => theme.globals.spacing}px;
`;

export const TextBoxContent = styled.div`
  overflow: hidden;
  padding: ${({ theme }) => theme.globals.spacing}px;
  padding-bottom: 2rem;
  word-break: break-word;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: ${({ theme }) => theme.colors.accent};
    height: 22px;
    z-index: 0;
  }

  img {
    margin: 0;
    margin-bottom: ${({ theme }) => theme.globals.spacing}px;
  }

  p,
  ul {
    margin-top: 0;
    margin-bottom: ${({ theme }) => theme.globals.spacing}px;
  }

  a {
    color: ${({ color, theme }) => color || theme.colors.link};
    text-decoration: none;
  }

  ul,
  ol {
    padding-left: 2rem;
  }

  ${({ resetPadding }) =>
    resetPadding &&
    css`
      padding: 0;
      padding-bottom: 1.7rem;
    `}
`;
