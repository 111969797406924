import styled from '@emotion/styled';

export const Contact = styled.div`
  p {
    margin-bottom: 0;
  }
`;

export const ContactPhone = styled.div`
  margin-top: 1.6rem;
  display: flex;
  align-items: center;

  svg {
    margin-right: 1.6rem;
  }
`;

export const ContactFax = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: 1.6rem;
  }
`;

export const ContactMail = styled.div`
  align-items: center;
  color: ${({ theme, color }) => color || theme.colors.link};
  display: flex;
  margin-top: 0;
  margin-bottom: 1.6rem;

  a {
    color: ${({ theme, color }) => color || theme.colors.link};
    text-decoration: none;
  }

  svg {
    margin-right: 1.6rem;
  }
`;
