import { css } from '@emotion/core';
import styled from '@emotion/styled';

export const ShowMore = styled.div``;

export const ShowMoreLink = styled.a`
  align-items: center;
  margin-bottom: 1.6rem;
  display: flex;
`;

export const ShowMoreContent = styled.div`
  max-height: 0;
  transition: max-height 0.3s;
  overflow: hidden;

  ${({ isOpen }) =>
    isOpen &&
    css`
      max-height: 2000px;
    `}
`;
