import { Mail, Phone, Print } from '@material-ui/icons';
import propTypes from 'prop-types';
import React from 'react';

import useContext from '../../hooks/useContext';
import Text from '../cockpit/text/Text';
import TextBox from '../cockpit/textBox/TextBox';
import * as S from './Contact.styles';

const Contact = ({ text, mobile, mail, fax }) => {
  const { currentPage } = useContext();
  const color = currentPage.color && currentPage.color.firstColor;
  return (
    <TextBox headline="Kontakt">
      <S.Contact>
        <Text text={text} />
        {mobile && (
          <S.ContactPhone>
            <Phone style={{ fontSize: 24 }} />
            {mobile}
          </S.ContactPhone>
        )}
        {fax && (
          <S.ContactFax>
            <Print style={{ fontSize: 24 }} />
            {fax}
          </S.ContactFax>
        )}
        {mail && (
          <S.ContactMail color={color}>
            <Mail style={{ fontSize: 24 }} />
            <a href={`mailto:${mail}`}>{mail}</a>
          </S.ContactMail>
        )}
      </S.Contact>
    </TextBox>
  );
};

Contact.propTypes = {
  text: propTypes.string,
  mobile: propTypes.string,
  mail: propTypes.string,
  fax: propTypes.string,
};

export default Contact;
