import styled from '@emotion/styled';

import mq from '../../styles/mq';

export const Stage = styled.div`
  color: ${({ theme }) => theme.colors.white};
  position: relative;
  height: 500px;
  overflow: hidden;

  ${mq.lg} {
    height: 650px;
  }
`;

export const StageText = styled.div`
  padding: ${({ theme }) => theme.globals.spacing}px;
  background-color: ${({ theme }) => theme.colors.primary};
  position: absolute;
  bottom: 36px;
  left: 0;
  width: 90%;
  max-width: 900px;
  hyphens: auto;
  z-index: 1;
`;

export const StageImage = styled.div`
  img {
    object-fit: cover;
    width: 100%;
    height: 500px;

    ${mq.lg} {
      height: 650px;
    }
  }
`;
