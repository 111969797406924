import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';

import useContext from '../../hooks/useContext';
import Image from '../cockpit/image/Image';
import * as S from './Navigation.styles';

const Navigation = () => {
  const localizedData = useContext();
  const router = useLocation();

  return (
    <S.Navigation>
      <Container>
        <S.NavigationContent>
          <S.NavigationList>
            {localizedData.pages.map((page) => {
              if (!page.published || !page.navigation) return null;
              const pathname = router.pathname.split('/')[1];

              return (
                <S.NavigationItem key={page.title} isActive={pathname === page.title_slug}>
                  <Link to={`/${page.title_slug}`} component={S.NavigationLink}>
                    <Image image={page.navigationIcon} />
                    {page.title}
                  </Link>
                </S.NavigationItem>
              );
            })}
          </S.NavigationList>

          {localizedData.currentPage.logo && (
            <S.PageIcon>
              <Image image={localizedData.currentPage.logo} />
            </S.PageIcon>
          )}
        </S.NavigationContent>
      </Container>
    </S.Navigation>
  );
};

export default Navigation;
