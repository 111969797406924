import styled from '@emotion/styled';
import mq from '../../styles/mq';

export const Header = styled.header`
  padding: ${({ theme }) => `50px ${theme.globals.spacing}px`};
  padding-bottom: 70px;
  background-color: ${({ theme }) => theme.colors.primary};
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: ${({ theme }) => theme.colors.accent};
    height: 20px;
    z-index: 0;
  }

  .extra-logo {
    img {
      margin-right: 0.8rem;
      width: 100px;
    }
  }

  a {
    align-items: center;
    color: ${({ theme }) => theme.colors.white};
    display: flex;
    font-size: 3rem;
    font-weight: bold;
    text-decoration: none;
    margin-bottom: 20px;

    img {
      margin-left: 0;
      margin-right: 0.8rem;
      width: 80px;
    }
  }

  ${mq.md} {
    a {
      font-family: ${({ theme }) => theme.fonts.secondary};
      font-size: 4.5rem;
    }
  }

  ${mq.lg} {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;

    a {
      margin-bottom: 0;
    }
  }
`;
