import React, { useContext } from 'react';
import LocalizationContext from '../../localization/context';
import Image from '../cockpit/image/Image';
import * as S from './Header.styles';

const Header = () => {
  const { siteSettings } = useContext(LocalizationContext);

  return (
    <S.Header>
      <div>
        <a href="/">
          <Image image={siteSettings.headerLogo} />
          {siteSettings.headerText}
        </a>
      </div>
      <div className="extra-logo">
        <Image image={siteSettings.extraLogo} />
      </div>
    </S.Header>
  );
};

export default Header;
