import styled from '@emotion/styled';

import mq from '../../../styles/mq';

export const Partner = styled.div``;

export const PartnerList = styled.ul`
  display: flex;
  flex-wrap: wrap;
`;

export const PartnerItem = styled.li`
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  margin-bottom: ${({ theme }) => theme.globals.spacing}px;
  padding: ${({ theme }) => `${theme.globals.spacing}px`};
  width: 100%;
  position: relative;

  &::after {
    background-color: ${({ theme }) => theme.colors.grey};
    content: '';
    display: none;
    pointer-events: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 2px;
    height: 50%;
    opacity: 0.5;
  }

  ${mq.lg} {
    padding: ${({ theme }) => `36px ${theme.globals.spacing}px 36px ${theme.globals.spacing}px`};

    &::after {
      display: block;
    }
  }
`;

export const PartnerLink = styled.a`
  align-items: center;
  color: ${({ theme }) => theme.colors.text};
  font-size: 2.8rem;
  text-decoration: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;

  h3 {
    width: 100%;
  }

  img {
    width: 300px;
    margin: 0;
  }

  ${mq.lg} {
    h3 {
      width: auto;
    }
  }

  ${mq.xl} {
    img {
      width: 500px;
    }
  }
`;
